/**
 * Shows a notification when the add-in command is executed.
 * @param event
 */
function action(event: Office.AddinCommands.Event) {
  const message: Office.NotificationMessageDetails = {
    type:       Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
    message:    "Performed action.",
    icon:       "Icon.80x80",
    persistent: true,
  };

  event.completed();
}
Office.actions.associate("action", action);


